import React from 'react'

import waltz_krem_rolls_ljesnjak from '../images/products/waltz-krem-rolls-ljesnjak.jpg'
import waltz_krem_rolls_cokoladni from '../images/products/waltz-krem-rolls-cokoladni.jpg'
import waltz_krem_rolls_mlecni from '../images/products/waltz-krem-rolls-mlecni.jpg'
import waltz_krem_rolls_narandza from '../images/products/waltz-krem-rolls-narandza.jpg'

class ChocoCreamRolls extends React.Component{
    render(){
        return(
            <section id="proizvodi" className="hero products-page default-page">
                <div className="container padding-0 margin-0">
                    <div className="columns margin-0 main">
                        <div className="column is-4 static-part is-left padding-0">
                            <div className="content">
                                <div className="title">CHOCO CREAM ROLLS</div>
                                <span className="zigzag"></span>
                                <p className="text">Budite inspirsani malim Choco Cream Rolls u kojima je sadržana 
                                ljubav prema potrošačima u svakom segmentu njene pripreme. U svakoj rolnici je sreća
                                 koju naše osoblje pažljivo pripremaju za vas. Pronađite je.</p>
                            </div>
                        </div>{/*STATIC PART END */}
                        <div className="column is-8 content-part is-right padding-0">
                            <div className="columns is-multiline inner margin-0">
                                <div className="column is-6 single-product padding-0">
                                    <img src={waltz_krem_rolls_cokoladni} alt="Waltz rolls čokoladni" />
                                </div>
                                <div className="column is-6 single-product padding-0">
                                    <img src={waltz_krem_rolls_ljesnjak} alt="Waltz rolls mlečni"/>
                                </div>
                                <div className="column is-6 single-product padding-0">
                                    <img src={waltz_krem_rolls_mlecni} alt="Waltz rolls narandža" />
                                </div>
                                <div className="column is-6 single-product padding-0">
                                    <img src={waltz_krem_rolls_narandza} alt="Waltz rolls lješnjak"/>
                                </div>
                            </div>
                        </div>{/* PRODUCTS PART END */}
                    </div>{/* COLUMNS END */}
                </div>{/* CONTAINER END */}
            </section>/* PRODUCTS PAGE SECTION END */
        );
    }
}

export default ChocoCreamRolls