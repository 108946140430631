import React from 'react'
import { Helmet } from "react-helmet"
import Layout from '../components/Layout'
import Header from '../components/Header'
import ChocoCreamRolls from '../components/ChocoCreamRolls'
import Footer from '../components/Footer'

class ChocoCreamRollsPage extends React.Component{
    render(){
        return(
            <Layout>
                <Helmet>
                <meta charset="utf-8"/>
                <title>WALTZ | Choco Cream Rolls</title>
                <meta name="description" content="Budite inspirsani malim Choco Cream Rolls u kojima je sadržana ljubav prema potrošačima u svakom segmentu njene pripreme."/>
                <meta name="image" content="https://waltz.rs/waltz_choco_cream_rolls_seo.jpg"/>
                <meta itemprop="name" content="WALTZ | Choco Cream Rolls"/>
                <meta itemprop="description" content="Budite inspirsani malim Choco Cream Rolls u kojima je sadržana ljubav prema potrošačima u svakom segmentu njene pripreme."/>
                <meta itemprop="image" content="https://waltz.rs/waltz_choco_cream_rolls_seo.jpg"/>
                <meta name="og:title" content="WALTZ | Choco Cream Rolls"/>
                <meta name="og:description" content="Budite inspirsani malim Choco Cream Rolls u kojima je sadržana ljubav prema potrošačima u svakom segmentu njene pripreme."/>
                <meta name="og:image" content="https://waltz.rs/waltz_choco_cream_rolls_seo.jpg"/>
                <meta name="og:url" content="https://waltz.rs/choco-cream-rolls"/>
                <meta name="og:site_name" content="WALTZ | Choco Cream Rolls"/>
                <meta name="og:locale" content="sr"/>
                <meta name="og:type" content="website"/>
                </Helmet>
              <Header />
              <ChocoCreamRolls />
              <Footer />
            </Layout>
        );
    }
}
  export default ChocoCreamRollsPage;
  